/**
 * Contains functionality for handling websocket connections.
 *
 * @file
 * @copyright 2019 Xerlife Ltd. All rights reserved.
 */
import $ from 'jquery';
import uuid from 'uuid/v4';

let _websocketApi = null;

/**
 * Handles communication with Xerlife through websockets. Provides APIs for
 * subscribing to model creations, updates and deletions.
 *
 * This class is a singleton, so no matter how many times it is instantiated
 * the same instance will be returned. This ensures a limit of one websocket
 * connection per page.
 */
class WebsocketManager
{
    constructor()
    {
        if (!_websocketApi)
        {
            _websocketApi = this;

            /**
             * Stores subscribers to updates for a particular model
             * instance.
             */
            this._objectSubscribers   = {};

            /**
             * Stores subscribers to updates for a particular model
             */
            this._modelSubscribers    = {};

            /**
             * Stores subscribers to task status changes for a given task
             * instance.
             */
            this._taskStatusSubscribers = {};

            this.connect();

            /**
             * Stores messages that are waiting to be delivered once the
             * websocket is opened.
             */
            this._pendingMessages = [];
            this._errorCallbacks  = [];
            this._subscriptions   = [];
        }

        return _websocketApi;
    }

    /**
     * Reconnects to the websocket after a delay of 1 second.
     */
    delayedReconnect()
    {
        console.log("Lost connection to websocket. Reconnecting in 1s...");
        setTimeout(
            this.reconnect.bind(this), 1000);
    }

    /**
     * Reconnects to the websocket, resubmitting the subscription requests.
     */
    async reconnect()
    {
        try
        {
            await this.connect();
            for (var subscription of this._subscriptions)
            {
                this.send(JSON.stringify(subscription));
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }

    /**
     * Connects to the websocket, setting up handlers for reconnecting
     * automatically, error handling, etc.
     *
     * @retval Promise A promise that resolves if the websocket connects
     *                 successfully, and rejects if the websocket does not.
     */
    connect()
    {
        const props = JSON.parse($("#xerlife-props").text());

        const websocketUrl = props.messagesWebsocketUrl;
        var urlPrefix;

        if (window.location.protocol === "https:")
        {
            urlPrefix = "wss://";
        }
        else
        {
            urlPrefix = "ws://";
        }

        /**
         * The connection to the websocket.
         */
        this._websocketConnection = new WebSocket(urlPrefix + websocketUrl);

        this._websocketConnection.onmessage = this.handleMessage.bind(this)
        this._websocketConnection.onopen    = this.handleOpen.bind(this)
        this._websocketConnection.onclose   = this.delayedReconnect.bind(this)

        return new Promise(
            function(resolve, reject)
            {
                this._websocketConnection.addEventListener("open", function()
                    {
                        resolve()
                    });
                this._websocketConnection.addEventListener(
                    "error",
                    function(err)
                    {
                        reject(err)
                    });
            }.bind(this));
    }

    /**
     * Adds a given callback to the list of those to be called when a given
     * model or model instance is created/updated/deleted in Xerlife.
     *
     * @param callback  The function to be called when a message is received.
     *                  This function must accept one argument, which will be
     *                  the content of the message.
     * @param modelName The name of the model class to which we are to
     *                  subscribe.
     * @param pk        Optional. The PK of the instance to which we are to
     *                  subscribe.
     */
    addSubscriber(callback, errorCallback, modelName, pk)
    {
        var content = {
            model: modelName
        };

        if (typeof(pk) !== "undefined")
        {
            var key   = modelName + "__" + pk
            var entry = this._objectSubscribers[key];

            if (typeof(entry) === "undefined")
            {
                this._objectSubscribers[key] = []
            }
            this._objectSubscribers[key].push(callback);

            content.pk = pk
        }
        else
        {
            var entry = this._modelSubscribers[modelName]

            if (typeof(entry) === "undefined")
            {
                this._modelSubscribers[modelName] = []
            }

            this._modelSubscribers[modelName].push(callback);
        }

        var identifier = uuid();

        var subscription = {
            typeName:   "modelUpdateSubscription",
            identifier: identifier,
            content:    content
        };

        this.send(JSON.stringify(subscription));

        this._errorCallbacks[identifier] = errorCallback;
        this._subscriptions.push(subscription);
    }

    /**
     * Adds a given callback to the list of those to be called when a given
     * task returns a status.
     *
     * @param callback The function to be called when a message is received.
     *                 The function must accept one argument, which will be
     *                 the content of the message.
     * @param taskId   The UUID that identifies the task to which we are
     *                 subscribing.
     */
    addTaskStatusSubscriber(callback, errorCallback, taskId)
    {
        var content = {
            taskId: taskId
        };

        var entry = this._taskStatusSubscribers[taskId];

        if (typeof(entry) === "undefined")
        {
            this._taskStatusSubscribers[taskId] = [];
        }

        this._taskStatusSubscribers[taskId].push(callback);

        var identifier = uuid();

        var subscription = {
            typeName:   "taskStatusSubscription",
            identifier: identifier,
            content:    content
        };

        this.send(JSON.stringify(subscription));

        this._errorCallbacks[identifier] = errorCallback;

        this._subscriptions.push(subscription);
    }

    /**
     * Sends a message through the websocket, or queues it up to be sent when
     * the websocket opens if it is not open already.
     *
     * @message The message to be sent, in string form.
     */
    send(message)
    {
        try
        {
            this._websocketConnection.send(message);
        }
        catch (DOMException)
        {
            this._pendingMessages.push(message);
        }
    }

    /**
     * Handles the websocket open event, sending any queued messages that
     * were requested to be sent before the websocket was opened.
     *
     * @param ev The event.
     */
    handleOpen(ev)
    {
        var limit = this._pendingMessages.length;

        for (var i = 0; i < limit; ++i)
        {
            var message = this._pendingMessages.shift();

            this.send(message);
        }
    }

    /**
     * Handles receiving a message through the websocket.
     *
     * @param ev The event.
     */
    handleMessage(ev)
    {
        var parsed = JSON.parse(ev.data);

        if (parsed["typeName"] === "modelUpdate")
        {
            var content = parsed["content"];
            var key     = content["model"] + "__" + content["pk"];
            var entry   = this._objectSubscribers[key];

            if (typeof(entry) !== "undefined")
            {
                for (var fun of entry)
                {
                    fun(parsed);
                }
            }

            const modelEntry = this._modelSubscribers[content["model"]];
            if (typeof(modelEntry) !== "undefined")
            {
                for (var fun of modelEntry)
                {
                    fun(parsed);
                }
            }
        }
        else if (parsed["typeName"] === "taskStatusUpdate")
        {
            var taskId = parsed["content"]["taskId"]
            var entry  = this._taskStatusSubscribers[taskId];

            if (typeof(entry) !== "undefined")
            {
                for (var fun of entry)
                {
                    fun(parsed);
                }
            }
        }
        else if (parsed["typeName"] === "errorResponse")
        {
            var identifier = parsed["identifier"];

            this._errorCallbacks[identifier](parsed);
        }
    }
}

export default WebsocketManager;
