import 'core-js';
import 'custom-event-polyfill';
import 'filepond-polyfill';
import 'react-bootstrap';
import 'bootstrap';
import React from "react";
import ReactDOM from "react-dom";
import $ from 'jquery';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css';
import './style.scss'
import moment from 'moment';
import DeleteLink from './delete.js';
import CollapseHeader from './collapse.js';
import WebsocketManager from './websocket.js';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Countdown, {zeroPad} from 'react-countdown-now';
import {windowProps} from './core.js';
import '@fortawesome/fontawesome-free/css/all.css';
import "ladda/dist/ladda-themeless.min.css";
require('moment/locale/en-gb');

function setup()
{
    moment.locale(window.xerlife.locale);
    window.xerlife.moment = moment;

    $('.xerlife-delete-link').each(function()
        {
            var el = $(this);

            var deleteLink = <DeleteLink {...el.data()} />

            ReactDOM.render(
                deleteLink,
                this);
        });

    registerPlugin(FilePondPluginFileValidateType,
        FilePondPluginFileValidateSize);

    $('.xerlife-filepond').each(function()
        {
        var el = $(this);

        var headers = {
            'X-CSRFToken': $('input[name="csrfmiddlewaretoken"]').val()}

        var filePondData = el.data()

        if (filePondData.previouslyUploaded)
        {
            if (!filePondData.files)
            {
                filePondData.files = [];
            }

            filePondData.files.push({
                source: filePondData.previouslyUploaded,
                options: {
                    type: 'local'
                }
            });

            delete filePondData.previouslyUploaded;
        }


        var filepond = <FilePond {...filePondData} server={{
            url: '/app/fp',
                process: { url: '/process/',
                        headers: headers,
                        withCredentials: false,
                        ondata: (formData) => {
                            var upload_field = '';
                            for (var item of formData.keys()) {
                                upload_field = item;
                                break;
                            }
                            if (upload_field !== '') {
                                formData.append('fp_upload_field',upload_field);
                            }
                            return formData;
                        },
                        onerror: (response) => {
                            console.log(response.data);
                        }
                },
                revert:  {
                    url:     '/revert/',
                    headers: headers
                },
                restore: {
                    url:     '/restore/',
                    headers: headers
                },
                load:    {
                    url:     '/load?id=',
                    headers: headers
                },
                fetch:   {
                    url:     '/fetch?target=',
                    headers: headers
                }
        }} />;

        ReactDOM.render(
                filepond,
                this);
        });

    $('.xerlife-collapse-header').each(function()
        {
            var el = $(this);

            var collapseHeader = <CollapseHeader {...el.data()} />

            ReactDOM.render(
                collapseHeader,
                this);
        });

    function renderCountdowns()
    {
        var el = $(this);

        var renderer = function(props)
        {
            var {
                total,
                days,
                hours,
                minutes,
                seconds,
                milliseconds,
                completed
            } = props;

            var suffix = "";
            if (days > 1)
            {
                suffix = windowProps["transStrings"]["Countdown"]["daysSuffix"];
            }
            else
            {
                suffix = windowProps["transStrings"]["Countdown"]["daySuffix"];
            }

            if (days > 0)
            {
                return <span>
                    {days} {suffix}, {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                </span>;
            }

            return <span class="text-danger">
                {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
            </span>;
        };

        var countdown = <Countdown
            {...el.data()}
            renderer = {renderer}
        />;

        ReactDOM.render(
            countdown,
            this);
    }

    $('.xerlife-countdown').each(renderCountdowns);

    $('form:not(#stripePaymentForm)').submit(function(){
        $(this).find("[type='submit']").prop('disabled',true);
    });

    $(function () {
          $('[data-toggle="popover"]').popover()
    })

    $('.popover-dismiss').popover({
          trigger: 'focus'
    })

    $(".historical-data-button").click(function()
        {
            var height = $(this).parents(".card").outerHeight();

            $(this).parents(".card").css("height", height + "px");
        });

    $(".card canvas").on("xerlife-canvas-ready", function(){
        $(this).parents(".card").css("height", "auto");
    });

    $(".xerlife-scroll-to").each(
        function()
        {
            const scrollTargetString = $(this).data("target");
            const scrollTarget       = $(scrollTargetString);
            if (scrollTarget.length > 0)
            {
                scrollTarget[0].scrollIntoView();
            }
        });
}



/**
 * Gets a cookie by name.
 *
 * @param name
 * The name of the cookie to retrieve
 *
 * @return
 * Returns the value of the cookie, or nothing
 */
function check_cookie_name(name){
          var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
          if (match) return match[2];
}

$(document).ready(setup);
