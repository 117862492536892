/**
 * Adds the ability to create delete buttons on any page of the app.
 *
 * @file
 * @copyright 2019 Xerlife Ltd. All rights reserved.
 */

import "./core.js"
import $ from 'jquery';
import React from "react";

/**
 * Delete button. Takes the text to be shown in "data-delete-text" and the
 * link to be posted to in "data-delete-href". Must be of class
 * "xerlife-delete-link" to be found in the DOM.
 */
export default class DeleteLink extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state= {
            error: false
        }
    }

    render()
    {
        return <a className="dropdown-item text-danger"
                onClick = { this.click.bind(this) }
                href="#">
                { this.props.deleteText }
            </a>;
    }

    click()
    {
        $.ajax(
            { url: this.props.deleteHref,
                type: "DELETE"
            })
            .then(this.onDone.bind(this))
            .fail(this.onError.bind(this));
    }

    onError(data)
    {
        alert("An error has occured. Please try again.");
    }

    onDone()
    {
        window.location.reload();
    }
}

