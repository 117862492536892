/**
 * Contains core functionality for Xerlife JavaScript.
 *
 * @file
 * @copyright 2019 Xerlife Ltd. All rights reserved.
 */
import $ from 'jquery';

export const windowProps = JSON.parse($("#xerlife-props").text());

/**
 * Sets up core functionality for AJAX requests.
 */
function coreSetup()
{
    var csrftoken = $("[name=csrfmiddlewaretoken]").val();

    function csrfSafeMethod(method) {
        // these HTTP methods do not require CSRF protection
        return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
    }
    $.ajaxSetup({
        dataType:    "json",
        contentType: "application/json",
        traditional: true,
        beforeSend:  function(xhr, settings) {
            if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader("X-CSRFToken", csrftoken);
            }
        }
    });
}

/**
 * Sends an action creation request with the given type name to the given URL.
 *
 * @param actionUrl  The URL to which the request is to be submitted.
 * @param actionName The type name of the action.
 */
export function createAction(actionUrl, actionName, typeSpecificData)
{
    var data = {
        "actionType": {
            "name": actionName
        }
    };

    if (typeof(typeSpecificData) != "undefined")
    {
        data.typeSpecificData = typeSpecificData;
    }

    const postData = JSON.stringify(data);

    return $.ajax(
        {
            type: "POST",
            url:  actionUrl,
            data: postData,
        }
    );
}

coreSetup();
