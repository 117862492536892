/**
 * Adds the ability to create collapsable dropdowns on any page.
 *
 * @file
 * @copyright 2019 Xerlife Ltd. All rights reserved.
 */

import ReactDOM from "react-dom";
import "./core.js"
import $ from 'jquery';
import React from "react";
import Button from 'react-bootstrap/Button';
import {windowProps} from "./core.js";

export default class CollapseHeader extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            symbol: "▼"
        };

        const expandTarget = $("#" + props.targetId);
        expandTarget.on("hide.bs.collapse", this.onHide.bind(this));
        expandTarget.on("show.bs.collapse", this.onShow.bind(this));
    }

    render()
    {
        return <button
            className     = "btn btn-link collapsed"
            type          = "button"
            data-toggle   = "collapse"
            data-target   = {"#" + this.props.targetId}
            aria-expanded = "false"
            aria-controls = {this.props.targetId}>
                {this.props.text + this.state.symbol}
            </button>;
    }

    onHide()
    {
        this.setState({
            symbol: "▼"
        });
    }

    onShow()
    {
        this.setState({
            symbol: "▲"
        });
    }
}

/**
 * Adds a button that toggles between expanding and collapsing all collapse
 * elements on the page, aside from the navbar.
 */
export class CollapseAllButton extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            collapsed: false
        };
    }

    /**
     * Handles clicks on the button.
     */
    handleClick()
    {

        if (this.state.collapsed)
        {
            $(".collapse:not(.navbar-collapse)").collapse("show");
        }
        else
        {
            $(".collapse:not(.navbar-collapse)").collapse("hide");
        }

        this.setState(
            {
                collapsed: !this.state.collapsed
            });
    }

    render()
    {
        const transStrings = windowProps["transStrings"]["CollapseAllButton"];
        const expandText   = transStrings["expandText"];
        const collapseText = transStrings["collapseText"];

        if (this.state.collapsed)
        {
            return <Button
                variant = "success"
                onClick = {this.handleClick.bind(this)}>
                {expandText}
            </Button>;
        }

        return <Button
            onClick = {this.handleClick.bind(this)}
            variant = "success">
            {collapseText}
            </Button>;
    }
}

function setup()
{
    $(".xerlife-collapse-all-button").each(
        function()
        {
            var el = $(this);

            var collapseAllButton = <CollapseAllButton {...el.data()} />;

            ReactDOM.render(
                collapseAllButton,
                this);
        });
}

$(document).ready(setup);
